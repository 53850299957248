import moment from 'moment'
import 'moment-timezone'

const TIMEZONE = 'America/Chicago'
/**
 * Determines if the given date and time are in the future of the current browser timestamp
 * dateStr: 'yyyy-mm-dd' 
 * timeStr: 'hh:mm:ss' - 24 hour time
 * offset: Int - amount of leeway time in minutes.
 *    Example - offset 15 would check to see if the dateStr/timeStr minus 15 minutes was in the future
 *    Defaults to 0
*/
export const isInFuture = (dateStr, timeStr, offset) => {
  if (dateStr) {
    let date = moment(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm:ss').locale('en-us').tz(TIMEZONE)
    let today = new moment().locale('en-us').tz(TIMEZONE)
    if (offset) date = date.subtract(offset, 'minutes')
    return date.isAfter(today)
  }
  return false
}

/**
 * time: expects 'HH:mm:ss' - 24 hour time
 * minusMinutes: how many minutes to subtract from time
 *  returns String in format - 'hh:mm a'
 */
export const formatTime = (time, minusMinutes) => {
  return moment(time, 'HH:mm:ss').subtract(minusMinutes || 0, 'minutes').format('h:mm a')
}
